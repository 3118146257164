<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.51581 6.59992C4.82706 6.59992 5.13831 6.71826 5.3758 6.95575L11.0002 12.581L16.6246 6.95576C17.0996 6.48158 17.8696 6.48158 18.3446 6.95576C18.8187 7.43074 18.8187 8.20076 18.3446 8.67574L11.8602 15.1601C11.3852 15.6343 10.6152 15.6343 10.1402 15.1601L3.65582 8.67574C3.18165 8.20076 3.18165 7.43074 3.65582 6.95575C3.89331 6.71826 4.20456 6.59992 4.51581 6.59992Z"
      fill=""
    />
  </svg>
</template>
